<template>
  <div class="helpWarp">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">常见问题</div>
      <div class="rightBtn"></div>
    </div>
    <van-collapse v-model="activeNames" class="questionBox" :border="false" @change="changeColl">
      <van-collapse-item title="Q：我上传收费视频可以挣多少钱？" name="1" :border="false">平台和up主收益分成是4/6，up主拿总收益60%</van-collapse-item>
      <van-collapse-item title="Q：为什么我的上传视频时设置了价格，通过审核后成免费视频了？" name="2" accordion :border="false">
        免费视频和收费视频的比例为2:1，即上传2个免费视频就可以上传1个收费视频。
        <br />
        且在个人主页同分类下，不能连续出现收费作品。
      </van-collapse-item>
      <van-collapse-item title="Q：为什么我的金币视频被修改价格？" name="3" :border="false">
        请参考上传规则中的定价
        <br />
        1.非原创短片，建议定价1-10金币
        <br />
        2.原创剪辑作品，建议定价10-20金币
        <br />
        3.原创举牌作品，建议定价30-50金币
        <br />
        4.认证UP主发布原创举牌长视频，建议定价50-200金币
      </van-collapse-item>
      <van-collapse-item title="Q：为什么我总是上传不成功？" name="4" :border="false">
        平台限制视频大小上限为300m，且视频不能低于30S。
      </van-collapse-item>
      <van-collapse-item title="Q：封面上传规则是什么？" name="5" :border="false">
        视频时长低于5分钟，横屏视频用横板封面，竖屏视频用竖板封面。 超过5分钟的就是长视频-影视，封面全部为横板封面
      </van-collapse-item>
      <van-collapse-item title="Q：审核未通过的原因是什么呢？" name="6" :border="false">
        您可在[创作中心-作品管理]，点击未通过按钮可查看具体原因。
      </van-collapse-item>
      <van-collapse-item title="Q：多久才能审核通过？" name="7" :border="false">审核时间为3-5工作日内，请在[创作中心]查收反馈。</van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
import { Collapse, CollapseItem } from 'vant';
export default {
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },
  data() {
    return {
      activeNames: [],
    };
  },
  methods: {
    changeColl(v) {
      v.length > 1 && v.splice(0, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.helpWarp {
  overflow-y: auto;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }
  .questionBox {
    padding: 4px 12px;
  }
  /deep/ .van-cell {
    padding: 11px 12px;
    box-sizing: border-box;
    background: #fff !important;
    margin-top: 6px;
  }
  /deep/ .van-cell::before {
    border-top: 1px solid #151515;
  }
  /deep/ .van-cell__title {
    font-size: 16px;
  }
  /deep/ .van-collapse-item__content {
    font-size: 14px;
    padding: 11px 12px;
    box-sizing: border-box;
    background: #fff;
    color: rgb(102, 102, 102);
  }
  /deep/ .van-collapse-item {
    background: #242424 !important;
  }
}
</style>
